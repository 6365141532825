<template>
  <b-row >
    <b-col cols="0" md="2" lg="3" xl="3"></b-col>
    <b-col cols="12" md="8" lg="6" xl="6">
      <b-card style="text-align: center;">
        <div style="text-align: center;">
          <div style="text-align: center;">
            <h1 :style="title_style" class="beotablet-title">Добродошли на званичну БеоТаблет интернет страницу!</h1>
          </div>
          <img :class="{'img-minus-margin-top-30': window_width > 768, 'img-minus-margin-top-60': window_width >= 1703 }" src="../../src/assets/images/beotablet-img.svg" alt="" style="width: 80%;">
        </div>
        <h3 :style="title_style" class="beotablet-title">Уколико желите да извршите активацију уређаја посетите страницу <br> <router-link to="activation">Активација</router-link></h3>
        <h3 :style="title_style" class="beotablet-title">Уколико желите да пријавите квар уређаја посетите страницу <br> <router-link to="instructions">Подршка</router-link></h3>
      </b-card>
    </b-col>
    <b-col cols="0" md="2" lg="3" xl="3"></b-col>

  </b-row>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol
  },
  created() {
    this.window_width = window.innerWidth
    window.addEventListener('resize', () => {
      this.window_width = window.innerWidth;
      console.log(this.window_width)
    })
  },
  computed: {
    title_style() {
      if(this.window_width > 320 && this.window_width < 576) {
        return  {
          'font-size': '1rem'
        }
      } else if(this.window_width >= 576 && this.window_width < 768) {
        return {
          'font-size': '1.4rem'
        }
      } else if(this.window_width >= 768) {
          return {
            'font-size': '2rem'
          }
      }
    }
  },
  data() {
    return {
      window_width: null
    }
  }
}
</script>

<style lang="scss">

.img-minus-margin-top-30 {
  margin-top: -30px;
}
.img-minus-margin-top-60 {
  margin-top: -60px;
}

</style>
